import GettingStarted from 'components/home/GettingStarted';
// import LatestNews from 'components/home/LatestNews';
// import LatestOdds from 'components/home/LatestOdds';
import SportsBooks from 'components/home/SportsBooks';
import { LeaguesConfigProvider } from 'context/LeaguesConfig';
import dbConnect from 'database';
import { BookDef } from 'interfaces/sportbook';
import Layout from 'layouts/default';
// import moment from 'moment';
import { GetStaticProps } from 'next';
// import {
// EventDef,
//     EventWithDefaultLine,
// } from 'services/external/leagues/types';
import { NewsArticle } from 'services/external/news/types';
import { fetchHeadlines } from 'services/news';
// import { getOpeningLines } from 'services/odds';
import { getReviewedSportBooks } from 'services/sportbooks';
import { getEnabledSports } from 'services/sports';
// import { TIMEZONE_DEFAULT_ODDS } from 'utils/constants/leagues';
import './index.module.scss';
import UpComingMatchUps from 'components/home/UpComingMatchUps';
import useUpCommingMatchUps from 'hooks/useUpCommingMatchUps';
import { useMemo } from 'react';
import { EventWithScoresAndOdds } from 'interfaces/events';
import 'moment-timezone';
import TopStories from 'components/home/TopStories';
type PageProps = {
    books: BookDef[];
    // openingOdds: { league: string; events: EventWithDefaultLine[] }[];
    sportsNews: NewsArticle[];
    // bettingNews: NewsArticle[];
};
const IndexPage = ({ books, sportsNews }: PageProps) => {
    const { events, loading } = useUpCommingMatchUps();

    const { available: availableEvents } = useMemo(() => {
        return (events || []).reduce<{
            available: EventWithScoresAndOdds[];
            restricted: EventWithScoresAndOdds[];
        }>(
            (all, curr) =>
                curr.restricted
                    ? {
                          ...all,
                          restricted: [...all.restricted, curr],
                      }
                    : {
                          ...all,
                          available: [...all.available, curr],
                      },
            { available: [], restricted: [] }
        );
    }, [events]);
    return (
        <LeaguesConfigProvider>
            <Layout home>
                {/* {openingOdds.length ? <LatestOdds odds={openingOdds} /> : null} */}
                {!loading ? (
                    <UpComingMatchUps events={availableEvents} />
                ) : null}
                <SportsBooks books={books} />
                {sportsNews.length ? (
                    <TopStories
                        title={`Top <span>Stories</span>`}
                        articles={sportsNews}
                    />
                ) : null}
                <GettingStarted />
            </Layout>
        </LeaguesConfigProvider>
    );
};

export const getStaticProps: GetStaticProps = async (_ctx) => {
    await dbConnect();

    const enabledLeagues = await getEnabledSports();

    const { sports: news } = await fetchHeadlines(enabledLeagues);

    const books = await getReviewedSportBooks();

    // const nowInET = moment().tz(TIMEZONE_DEFAULT_ODDS);
    // Fetch opening lines
    // const mappedEvents = (
    //     await Promise.all(
    //         enabledLeagues.map<
    //             Promise<null | { league: string; events: EventDef[] }>
    //         >(getOpeningLines.bind(null, nowInET))
    //     )
    // ).filter(Boolean);

    return {
        props: {
            books,
            // openingOdds: mappedEvents,
            sportsNews: news,
            // bettingNews: betting,
        },
        revalidate: 7200, // 2 hours
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every hour
        // revalidate: 1 * MINUTES_PER_HOUR * SECONDS_PER_MINUTE,
    };
};

export default IndexPage;
